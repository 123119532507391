<template>
  <div class="container">
    <div class="title"><span @click="previousPage()">&lt;</span>激活校园ID</div>
    <div class="content">
      <div class="input1">
        <img src="../../static/name.png" class="img">
        <input
          v-model="name"
          type="text"
          placeholder="姓   名"
          placeholder-style="color:#BFBDBC"
          class="unborder"
        >
        <span v-if="name_show">请输入内容</span>
        <img src="../../static/x.png" class="img imgx" @click="qk()">
      </div>
      <div class="input1">
        <img src="../../static/xuenum.png" class="img">
        <input
          v-model="workNo"
          type="text"
          placeholder="学号/工号"
          placeholder-style="color:#BFBDBC"
          class="unborder"
        >
        <span v-if="workNo_show">请输入内容</span>
      </div>
      <div class="input1">
        <img src="../../static/cType.png" class="img">

        <el-select v-model="cType" placeholder="证件类型" style="width: 80%">
          <el-option
            v-for="item in cTypeData"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="input1 input-end">
        <img src="../../static/shengfen.png" class="img">
        <input
          v-model="ids"
          type="text"
          placeholder="证件号码"
          placeholder-style="color:#BFBDBC"
          class="unborder"
        >

        <span v-if="ids_show">请输入内容</span>
        <span v-else style="width: 60vw; height: 4vw; position: absolute">
          <span
            v-if="ids_show2"
            style="position: absolute; left: 0; top: 0"
          >身份证号码格式错误</span>
        </span>
      </div>
    </div>
    <span
      v-if="show"
      style="color: red; position: relative; bottom: 27vw; left: 15vw"
      v-text="msgshow"
    />
    <div class="footer">
      <span class="end" @click="click()">下一步</span>
    </div>
  </div>
</template>

<script>
import { checkIdentity, getSomething } from '../../api';
import { isIdentity } from '../../util/validate';
export default {
  data() {
    return {
      workNo: '', // 学/工号
      ids: '', // 身份证号
      cType: 'idcard', // 证件类型
      name: '',
      to: 'Active1-app',
      flag: false,
      ids_show: false,
      ids_show2: false,
      name_show: false,
      workNo_show: false,
      show: false,
      msgshow: '',
      cTypeData: []
    };
  },
  updated() {
    this.ids_show = !this.ids;

    this.name_show = !this.name;
    this.workNo_show = !this.workNo;
    if (this.cType === 'idcard' && this.ids !== '') {
      return (this.ids_show2 = !isIdentity(this.ids));
    }
    if (this.cType !== 'idcard') {
      this.ids_show2 = false;
    }
    if (this.ids && this.name && this.workNo && this.cType) { this.to = 'Active2-app'; }
  },
  mounted() {
    this.getType();
  },
  methods: {
    // 获取证件类型
    getType() {
      getSomething().then((res) => {
        const { code, data, msg } = res.data;
        if (code === 200) {
          this.cTypeData = data;

          console.log(data);
        } else {
          this.$message.error(msg);
        }
      });
    },
    previousPage() {
      history.go(-1);
    },
    qk() {
      this.name = '';
    },
    click() {
      this.ids_show = !this.ids;
      //   console.log(this.ids_show,this.ids);

      this.name_show = !this.name;
      this.workNo_show = !this.workNo;
      //   if (this.cType === "idcard") {
      //     return (this.ids_show2 = isIdentity(this.ids) ? false : true);
      //   }
      //   if (this.cType !== "idcard") {
      //     this.ids_show2 = false;
      //   }
      //   console.log(this.workNo,this.ids,this.name,this.cType)
      this.workNo &&
        this.ids &&
        this.name &&
        this.cType &&
        checkIdentity({
          ext: 'string',
          id: 'string',
          identity: 'string',
          workNo: this.workNo,
          ids: this.ids,
          name: this.name,
          cType: this.cType
        })
          .then((res) => {
            if (res.data.code === 200) {
              this.show = false;
              this.$router.push({
                name: 'Active2-app',
                params: {
                  code: res.data.data,
                  workNo: this.workNo,
                  name: this.name,
                  ids: this.ids,
                  cType: this.cType
                }
              });
            } else {
              this.msgshow = res.data.msg;
              this.show = true;
              setTimeout(() => (this.show = false), 5000);
            }
          })
          .catch((err) => {
            this.$message.error(err.response.data.errorDescription);
            this.$router.push({
              name: this.to
            });
          });
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
//   padding: 0;
}

@media screen and(-ms-high-contrast:active), (-ms-high-contrast: none) {
  /*兼容IE11*/
  span {
    position: absolute;
    width: 50vw;
    left: 11vw;
  }
  .end {
    left: 0;
  }
}
/deep/ .el-input__inner {
  border: none;
  padding-left: 0;
  //   height: 4vh;
  font-size: 3.5vw;
}
/deep/ input::-webkit-input-placeholder {
  color: #5f5f5f;
}
/deep/ .el-select-dropdown__list {
  padding: 30px !important;
}

input {
  outline: none;
}

.container {
  width: 100vw;
  height: 178vw;
}

.content {
  padding: 10.68vw 10vw;
  margin-top: 13.47vw;
}

.title {
  width: 100%;
  height: 12.5vw;
  line-height: 12.5vw;
  font-size: 4.8vw;
  color: #fff;
  text-align: center;
  background-color: #da251c;
  position: absolute;
  top: 0;
}

.title span {
  position: absolute;
  left: 2vw;
  font-size: 8vw;
}

.img {
  width: 5.3vw;
  height: 5.3vw;
  margin: 0 3vw 0 3.2vw;
}

.img-yz {
  width: 7vw;
  height: 5.3vw;
  margin: 0 3vw 0 3.2vw;
}

.input1 {
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 10.68vw;
  width: 80vw;
  color: #969696;
  border: 1px solid #bfbdbc;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10vw;
}

/* 输入提示消息 */
.input1 span {
  color: red;
  position: absolute;
  top: 13vw;
  font-size: 3.5vw;
}

.input-end {
  margin-bottom: 25vw;
}

input {
  font-size: 3.2vw;
  width: 60vw;
}

.unborder {
  border: none;
}
select {
  font-size: 3.2vw;
  width: 60vw;
}

.footer {
  position: static;
  width: 100%;
  height: 73.25vw;
  background-image: url("../../static/wangbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 10vw;
}

.end {
  position: relative;
  top: -15vw;
  display: block;
  width: 63.3vw;
  height: 10.68vw;
  background-image: url("../../static/denglu.png");
  background-size: 100% 100%;
  font-size: 4vw;
  color: #fff;
  text-align: center;
  line-height: 10.68vw;
  margin-left: 7.7vw;
  text-decoration: none;
  cursor: pointer;
}
</style>
